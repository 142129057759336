<template>
  <app-banner />
  <!-- Confirmation row -->
  <div class="container card-container" v-if="!showSuccess">
    <div class="row">
      <div class="col-10 success-col">
        <app-card>
          <template #title>{{ $t('unsub.title') }}</template>
          <template #text>{{ $t('unsub.confirmation') }}</template>
          <template #button>
            <app-button @clicked="unsubscribe">
              {{ $t('unsub.btn') }}
            </app-button>
          </template>
        </app-card>
      </div>
    </div>
  </div>
  <!-- Success row -->
  <div class="container card-container" v-if="showSuccess">
    <div class="row">
      <div class="col-10 success-col">
        <app-card>
          <template #title>{{ $t('unsub.success') }}</template>
          <template #text>{{ $t('unsub.text') }}</template>
          <template #button>
            <p>
              <localized-link to="/">
                <app-button>
                  {{ $t('success.back') }}
                </app-button>
              </localized-link>
            </p>
          </template>
        </app-card>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '@/utils/services/UserService';
export default {
  data() {
    return {
      user: '',
      showSuccess: false,
    };
  },
  created() {
    this.user = this.$route.params.id;
  },
  methods: {
    unsubscribe() {
      UserService.unsubscribe(this.$route.params.id)
        .then((res) => {
          if (res.status === 200) {
            this.showSuccess = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
